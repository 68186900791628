import AsyncSearchInput from 'components/common/asyncSearchInput'
import React from 'react'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import { Button } from 'components/common/buttons'

const I18N = i18nPath('views.integrations_page.external_knowledge_section')

type Props = {
  searchQuery?: string
  setSearchQuery: (query: string) => void
  onAddDocuments: () => void
}

const ActionsAndSearch: React.FC<Props> = ({ searchQuery, setSearchQuery, onAddDocuments }) => (
  <div className='d-flex justify-content-between align-items-center mb-3'>
    <AsyncSearchInput
      placeholder={I18N('search_by_title')}
      onKeyUp={setSearchQuery}
      className='mb-0'
      defaultValue={searchQuery}
    />

    <div>
      <Button onClick={onAddDocuments}>
        {I18NCommon('add_more_documents')}
      </Button>
    </div>
  </div>
)

export default ActionsAndSearch
