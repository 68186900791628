import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import { i18nPath } from 'utils/i18nHelpers'
import GlobeIcon from 'components/icons/globeIcon'
import LockIcon from 'components/icons/lockIcon'
import pageWorkspaceSlice from 'redux/slices/pageWorkspaces'
import AudienceSelectorModal, { AUDIENCE_MODAL_DISABLED, AUDIENCE_MODAL_SHARED } from 'components/pages/audienceSelectorModal'
import useAudienceUserIds from 'components/common/hooks/useAudienceUserIds'

const I18N = i18nPath('views.pages')

const ViewableByLink = ({
  linkClassName = 'font-weight-400',
  page,
}) => {
  const isPublic = !page.restrictAccess
  const Icon = isPublic ? GlobeIcon : LockIcon
  const workspace = useSelector(pageWorkspaceSlice.selectors.getWorkspace(page.pageWorkspaceId))
  const workspaceRestricted = workspace?.restrictAccess

  const [audienceSelectorModalMode, setAudienceSelectorModalMode] = useState(AUDIENCE_MODAL_DISABLED)

  const { isLoading, audienceUserIds } = useAudienceUserIds({ targetingRules: page.targetingRules, moduleName: 'pages' })
  const count = audienceUserIds.length

  const getText = () => {
    if (isLoading) {
      return I18N('viewable_by_loading')
    } else if (isPublic && workspaceRestricted) {
      return I18N('viewable_by_workspace')
    } else if (isPublic) {
      return I18N('viewable_by_everyone')
    } else {
      return I18N('viewable_by_people', { count })
    }
  }

  return (
    <>
      <a className='ViewableByLink Toolbar-link d-flex align-items-center' onClick={() => setAudienceSelectorModalMode(AUDIENCE_MODAL_SHARED)}>
        <Icon className='mr-1' />
        <span className={classNames('align-middle', linkClassName)}>{getText()}</span>
      </a>
      {audienceSelectorModalMode !== AUDIENCE_MODAL_DISABLED && (
        <AudienceSelectorModal
          modalMode={audienceSelectorModalMode}
          onCloseModal={() => setAudienceSelectorModalMode(AUDIENCE_MODAL_DISABLED)}
          page={page}
        />
      )}
    </>
  )
}

export default ViewableByLink
