import React, { useState } from 'react'
import useFetch from 'components/common/hooks/useFetch'
import API from 'services/api'
import ClearyCard from 'components/common/card'
import CdnSvg from 'components/common/cdnSvg'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import { i18nPath } from 'utils/i18nHelpers'
import AddChannelsModal from 'pages/ai_platform/dashboardPage/addChannelsModal'

const I18N = i18nPath('views.ai_assistant.dashboard.left_sidebar')

const Channels = () => {
  const [activeChannels, setActiveChannels] = useState<any[]>([])
  const [showAddChannelsModal, setShowAddChannelsModal] = useState(false)
  const [suggestedChannels, setSuggestedChannels] = useState<any[]>([])

  const fetchAllSuitableChannels = () => API.chat.aiAssistantSuitableChannels.fetchAll({
    excludeChannelIds: activeChannels.map(({ id }) => id) || [],
  })

  const {
    isLoading: isLoadingSuggestedChannels,
  } = useFetch(fetchAllSuitableChannels, [activeChannels], { onSuccess: setSuggestedChannels })

  const {
    isLoading: isLoadingActiveChannels,
    isLoaded: isLoadedActiveChannels,
  } = useFetch(API.ai.assistant.activeChannels.fetchAll, [], { onSuccess: setActiveChannels })

  return (
    <>
      <ClearyCard
        title={(
          <>
            <div className='h5'>{I18N('channels_title')}</div>

            <div
              className='pointer'
              onClick={() => setShowAddChannelsModal(true)}
              data-testid='add-channel-button'
            >
              <CdnSvg src='/images/plusIcon.svg' />
            </div>
          </>
        )}
        className='mt-4'
      >
        {isLoadingActiveChannels && <CirclesLoadingIndicator />}
        {isLoadedActiveChannels && activeChannels.length === 0 && (
          <div className='text-center text-secondary text-smallest'>{I18N('no_channels')}</div>
        )}
        {isLoadedActiveChannels && activeChannels.length > 0 && (
          <div>
            {activeChannels.map(({ id, name, externalUrl }) => (
              <div className='AiPlatformDashboardPage__sidebar__channel--link' key={id}>
                <a href={externalUrl} target='_blank' rel='noopener noreferrer'>
                  #{name}
                </a>
              </div>
            ))}
          </div>
        )}
      </ClearyCard>

      {showAddChannelsModal && (
        <AddChannelsModal
          visible
          setVisible={setShowAddChannelsModal}
          suggestedChannels={suggestedChannels}
          isLoadingSuggestedChannels={isLoadingSuggestedChannels}
          setActiveChannels={setActiveChannels}
        />
      )}
    </>
  )
}

export default Channels
