import { ExternalSyncSource } from 'components/admin/integrations/external_sync/types'
import { ButtonNarrow } from 'components/common/buttons'
import React from 'react'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import { getHelpText } from 'components/admin/integrations/external_sync/helpers'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.integrations_page.external_knowledge_section.initial_empty_state')

type Props = {
  onAddDocuments: () => void
  source: ExternalSyncSource
}

const InitialEmptyState: React.FC<Props> = ({ onAddDocuments, source }) => (
  <div className='InitialEmptyState text-center my-5 py-5 white-card'>
    <CdnSvg src='/images/illustrations/manSelectingDocuments.svg' className='mt-5' />

    <h2 className='mt-4'>{I18N('title')}</h2>
    <p className='mb-4'>{I18N('description')}</p>

    <ButtonNarrow onClick={onAddDocuments}>
      {I18NCommon('import_documents')}
    </ButtonNarrow>

    <p className='text-small text-secondary mt-3 mb-5'>
      {getHelpText(source.type)}
    </p>
  </div>
)

export default InitialEmptyState
