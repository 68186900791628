import React from 'react'
import { i18nFormat, i18nPath } from 'utils/i18nHelpers'
import ClearyCard from 'components/common/card'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import Channels from 'pages/ai_platform/dashboardPage/channels'

const I18N = i18nPath('views.ai_assistant.dashboard.left_sidebar')

const Sidebar = () => {
  const currentCompany = useCurrentCompany()

  return (
    <div className='AiPlatformDashboardPage__sidebar'>
      <ClearyCard>
        <h3 className='text-center'>
          {i18nFormat(I18N('greeting', { companyName: currentCompany.name }), <br />)}
        </h3>
      </ClearyCard>

      <Channels />
    </div>
  )
}

export default Sidebar
