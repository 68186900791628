import React from 'react'
import { ExternalSyncDocument } from 'components/admin/integrations/external_sync/types'
import CdnSvg from 'components/common/cdnSvg'

type DocumentProps = {
  document: ExternalSyncDocument
  onClick?: () => void
}

const Document: React.FC<DocumentProps> = ({ document, onClick }) => (
  <div>
    <CdnSvg className='DocumentIcon d-inline-block mr-2' src={document.iconPath} />
    {document.descendantsCount > 0 ? (
      <a onClick={onClick}>{document.title}</a>
    ) : (
      <span>{document.title}</span>
    )}
  </div>
)


export default Document

