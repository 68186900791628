import React, { useState } from 'react'

import { Button } from 'components/common/buttons'
import { i18nPath, i18nFormat, I18NCommon } from 'utils/i18nHelpers'
import { buildCompanyConfigurationPayload } from 'redux/slices/companyConfigurations'
import ReactSelect from 'components/common/react_select'
import CheckBox from 'components/common/buttons/checkBox'
import BetaTag from 'components/common/tags/betaTag'
import Card from 'components/common/card'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import { present } from 'components/common/utils'

const I18N = i18nPath('views.admin.other_settings.ai_tab')

const AI_ZENDESK_LINK = 'https://gocleary.zendesk.com/hc/en-us/articles/20368842880283-Enhancing-Content-Creation-with-AI-on-Cleary'
const AI_TEXT_GENERATION_MODELS = [
  { value: 'anthropic.claude-3-opus-20240229-v1:0', label: 'Claude 3 Opus' },
  { value: 'anthropic.claude-3-5-sonnet-20240620-v1:0', label: 'Claude 3.5 Sonnet' },
  { value: 'anthropic.claude-3-haiku-20240307-v1:0', label: 'Claude 3 Haiku' },
  { value: 'gpt-4', label: 'GPT-4' },
  { value: 'gpt-4o', label: 'GPT-4o' },
  { value: 'gpt-4o-mini', label: 'GPT-4o Mini' },
]

const AiTab = () => {
  const { settings } = useCurrentCompany()

  const [textGenerationWorkingCopy, setTextGenerationWorkingCopy] = useState(settings.aiTextGeneration)
  const [searchWorkingCopy, setSearchWorkingCopy] = useState(settings.aiAssistantAnswers)

  const [updateCompanyConfig, { isLoading: isSaving }] = useApi(API.admin.companyConfigurations.update)

  const areTextGenerationSettingsChanged = !_.isEqual(settings.aiTextGeneration, textGenerationWorkingCopy)
  const areSearchSettingsChanged = !_.isEqual(settings.aiAssistantAnswers, searchWorkingCopy)
  const areChangesPresent = areTextGenerationSettingsChanged || areSearchSettingsChanged

  const handleSaveChanges = () => {
    Promise.all([
      areTextGenerationSettingsChanged && updateCompanyConfig(buildCompanyConfigurationPayload('ai_text_generation', textGenerationWorkingCopy)),
      areSearchSettingsChanged && updateCompanyConfig(buildCompanyConfigurationPayload('ai_assistant_answers', searchWorkingCopy)),
    ].filter(present)).then(() => window.location.reload())
  }

  return (
    <section className='AiTab'>
      <div className='white-card p-3'>
        <div className='d-flex align-items-center mb-2'>
          <h6 className='mb-0'>{I18N('title')}</h6>
          <BetaTag className='ml-2' />
        </div>
        <p className='text-small text-secondary mb-3'>
          {i18nFormat(
            I18N('description'),
            <a href={AI_ZENDESK_LINK} target='_blank' rel='noopener noreferrer'>
              {I18N('learn_more')}
            </a>
          )}
        </p>
        <AiTextGenerationCard
          workingCopy={textGenerationWorkingCopy}
          setWorkingCopy={setTextGenerationWorkingCopy}
        />
        <AiAssistantAnswersCard
          workingCopy={searchWorkingCopy}
          setWorkingCopy={setSearchWorkingCopy}
        />
      </div>

      <div className='d-flex justify-content-end mt-5'>
        <Button
          onClick={handleSaveChanges}
          showLoadingSpinner={isSaving}
          disabled={isSaving || !areChangesPresent}
        >
          {I18NCommon('save_changes')}
        </Button>
      </div>
    </section>
  )
}

const AiTextGenerationCard = ({ workingCopy, setWorkingCopy }) => {
  const { enabled, defaultModel } = workingCopy

  return (
    <Card hasBoxShadow={false} hideBorder={false} className='mb-3'>
      <CheckBox
        id='ai-text-generation-enabled'
        checked={enabled}
        onChange={value => setWorkingCopy({ ...workingCopy, enabled: value })}
        label={I18N('enable_ai_text_generation')}
      />
      {workingCopy.enabled && (
        <ReactSelect
          className='w-75'
          options={AI_TEXT_GENERATION_MODELS}
          value={AI_TEXT_GENERATION_MODELS.find(model => model.value === defaultModel)}
          onChange={selected => setWorkingCopy({ ...workingCopy, defaultModel: selected.value })}
        />
      )}
    </Card>
  )
}

const AiAssistantAnswersCard = ({ workingCopy, setWorkingCopy }) => {
  const { permissions } = useCurrentUser()
  const { enabled, slackAppEnabled, autoGenerateFaqs } = workingCopy

  return (
    <Card hasBoxShadow={false} hideBorder={false}>
      <CheckBox
        id='ai-search-enabled'
        checked={enabled}
        onChange={value => setWorkingCopy({ ...workingCopy, enabled: value })}
        label={I18N('enable_ai_assistant_answers')}
      />
      {workingCopy.enabled && (
        <CheckBox
          id='ai-search-slack-app-enabled'
          checked={slackAppEnabled}
          onChange={value => setWorkingCopy({ ...workingCopy, slackAppEnabled: value })}
          label={I18N('enable_ai_slack_app')}
        />
      )}
      {permissions.clearyAdmin && workingCopy.enabled && workingCopy.slackAppEnabled && (
        <CheckBox
          id='ai-search-auto-generate-faqs-enabled'
          checked={autoGenerateFaqs}
          onChange={value => setWorkingCopy({ ...workingCopy, autoGenerateFaqs: value })}
          label={I18N('auto_generate_faqs')}
        />
      )}
    </Card>
  )
}

export default AiTab
