import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import {
  I18NCommon, i18nPath
} from 'utils/i18nHelpers'

import { Button } from 'components/common/buttons'
import useApi from 'components/common/hooks/useApi'
import { LoadingContainer } from 'components/common/loadingContainer'
import FormErrorList from 'components/errors/formErrorList'
import { useParams, useHistory } from 'react-router-dom'
import audienceSlice from 'redux/slices/audiences'
import { showToastMessage } from 'redux/slices/toasts'
import API from 'services/api'
import { isAudienceValid } from 'utils/audience'
import normalizeTargetingRules, { TARGET_ENTIRE_COMPANY } from 'utils/normalizeTargetingRules'
import BackButton from 'components/common/backButton'
import classNames from 'classnames'
import VersionsTable from 'components/common/versionsTable'
import AudienceToggleSelector from 'components/common/audience/audienceToggleSelector'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import { Banner } from 'components/banners/banners'

const I18N = i18nPath('views.admin.restricted_audience_configuration_editor')


const payloadAttrs = [
  'id',
  'name',
  'enableNotifications',
  'enabledConfigurations',
  'hideUsers',
  'visibleInOrgChart',
]
const buildPayload = restrictedAudienceConfiguration => ({
  ..._.pick(restrictedAudienceConfiguration, payloadAttrs),
  targetingRules: normalizeTargetingRules(restrictedAudienceConfiguration.targetingRules),
})

const ENABLED_CONFIGURATIONS_SETTINGS = [
  'aiAssistantAnswers',
  'appLauncher',
  'badges',
  'banners',
  'carousel',
  'celebrations',
  'celebrations.manualCelebrations',
  'celebrations.birthdays',
  'celebrations.newEmployees',
  'celebrations.workAnniversaries',
  'comments.articles',
  'comments.awardedBadges',
  'comments.shoutouts',
  'externalAlerts',
  'golinks',
  'groups.groupPosts',
  'groups',
  'legalNotices.contentFooter',
  'legalNotices.nuxModal',
  'logos',
  'news',
  'orgChart',
  'pages',
  'pages.faqs',
  'people',
  'qna.commentsOnQuestions',
  'qna',
  'shoutouts',
  'slackFeedback',
  'softLaunchWhitelist',
  'surveys',
]

const SEARCH_SETTINGS = [
  'bloomfireSearch',
  'confluenceSearch',
  'globalSearch.apps',
  'globalSearch.attachedFileContents',
  'globalSearch.golinks',
  'globalSearch.groups',
  'globalSearch.news',
  'globalSearch.pages',
  'globalSearch.pageFaqs',
  'globalSearch.pageWorkspaces',
  'globalSearch.qna',
  'globalSearch.userSkills',
  'googleDriveSearch',
  'notionSearch',
]

const FEED_SETTINGS = [
  'feed.enabled',
  'feed.showCompanyCalendar',
]

const JOURNEYS_SETTINGS = [
  'journeys',
  'journeys.customJourneys',
  'journeys.offboarding',
  'journeys.onboardingBuddies',
  'journeys.preboarding',
  'journeys.stepTypes.calendarEvents',
  'journeys.stepTypes.communications',
  'journeys.stepTypes.introductions',
  'journeys.stepTypes.meetings',
  'journeys.stepTypes.surveys',
  'journeys.stepTypes.tasks',
]

interface SettingsCheckboxesProps {
  workingCopy: any,
  generateChangeHandler: any,
  settings: string[]
  appendEnabledToSettingsKey?: boolean
}

const SettingsCheckboxes = ({
  settings, appendEnabledToSettingsKey, workingCopy, generateChangeHandler,
}: SettingsCheckboxesProps) => {
  const { permissions: { clearyAdmin } } = useCurrentUser()
  const key = setting => (appendEnabledToSettingsKey ? `${setting}.enabled` : setting)

  return (
    <>
      {settings.map(setting => (
        <div className='row' key={setting}>
          <div className='form-group col'>
            <div className='form-check'>
              <input id={`${_.snakeCase(setting)}-checkbox`} disabled={!clearyAdmin} type='checkbox' checked={_.get(workingCopy, `enabledConfigurations.${key(setting)}`) || false} onChange={generateChangeHandler(`enabledConfigurations.${key(setting)}`)} />
              <label className='checkbox-label form-inline-check' htmlFor={`${_.snakeCase(setting)}-checkbox`}>{I18N(_.snakeCase(setting))}</label>
            </div>
          </div>
        </div>
      ))}
    </>
  )
}

const EditRestrictedAudienceConfigurationPage = () => {
  const currentCompany = useCurrentCompany()

  // enabledConfiguration default values will be same as company values
  const defaultWorkingCopy = useMemo(() => {
    const defaultEnabledConfigurations: any = {}

    const keys = [
      [ENABLED_CONFIGURATIONS_SETTINGS, SEARCH_SETTINGS, JOURNEYS_SETTINGS].flat().map(k => `${k}.enabled`),
      FEED_SETTINGS,
    ].flat()

    keys.forEach(k => _.set(defaultEnabledConfigurations, k, _.get(currentCompany.settings, k)))

    return {
      name: '',
      targetingRules: TARGET_ENTIRE_COMPANY,
      enableNotifications: true,
      hideUsers: false,
      visibleInOrgChart: true,
      enabledConfigurations: defaultEnabledConfigurations,
    }
  }, [])

  const [workingCopy, setWorkingCopy] = useState<Record<string, any>>(defaultWorkingCopy)
  const dispatch = useDispatch()
  const history = useHistory()

  const { permissions: { clearyAdmin } } = useCurrentUser()

  const { id } = useParams()

  const [fetch, {
    data: fetchedData, isLoading, error,
  }] = useApi(API.admin.restrictedAudienceConfigurations.fetch)

  const [update, { data: updatedData, isLoading: isUpdating, error: updateError }] = useApi(
    API.admin.restrictedAudienceConfigurations.update,
    {
      onSuccess: () => {
        dispatch(showToastMessage({ message: I18NCommon('updated_successfully'), type: 'success' }))
      },
    }
  )

  // after an update happens, we will going to use the data that comes from update instead of the data from fetch
  const data = updatedData || fetchedData

  const [create, { isLoading: isCreating, error: createError }] = useApi(
    API.admin.restrictedAudienceConfigurations.create,
    {
      onSuccess: (data) => {
        dispatch(showToastMessage({ message: I18NCommon('created_successfully'), type: 'success' }))
        history.push(`/admin/restricted_audiences/${data.id}`)
      },
    }
  )

  const [destroy, { isLoading: isDestroying }] = useApi(
    API.admin.restrictedAudienceConfigurations.destroy,
    {
      onSuccess: () => {
        dispatch(showToastMessage({ message: I18NCommon('deleted_successfully'), type: 'success' }))
        history.push('/admin/restricted_audiences')
      },
    }
  )

  const updatedAt = data?.updatedAt || null

  const isNew = id === 'new'

  useEffect(() => {
    if (isNew) {
      setWorkingCopy(defaultWorkingCopy)
    } else {
      fetch(id)
    }
    return () => {
      setWorkingCopy(defaultWorkingCopy)
    }
  }, [id])

  useEffect(() => {
    if (data) {
      setWorkingCopy(_.cloneDeep(data))
    }
  }, [updatedAt])

  const updateWorkingCopy = (values = {}) => {
    setWorkingCopy({ ...workingCopy, ...values })
  }

  const generateChangeHandler = configPath => (e) => {
    const updatedValue = e.target.type === 'checkbox' ? e.target.checked : e.target.value

    const newWorkingCopy = _.cloneDeep(workingCopy)

    _.set(newWorkingCopy, configPath, updatedValue)

    updateWorkingCopy(newWorkingCopy)
  }

  const onSave = () => {
    const payload = buildPayload(workingCopy)
    if (isNew) {
      create(payload)
    } else {
      update(payload)
    }
  }

  const onPreview = async () => {
    await API.admin.restrictedAudienceConfigurations.startPreview(buildPayload(workingCopy))
    // force update and go to home
    window.open('/', '_blank')
  }

  const onDelete = async () => {
    if (confirm(I18NCommon('are_you_sure'))) {
      destroy(workingCopy)
    }
  }

  const baseUrlRequired = !workingCopy?.enabledConfigurations?.feed?.enabled
  const missingBaseUrl = baseUrlRequired && !workingCopy?.enabledConfigurations?.home?.baseUrl
  const saveError = updateError || createError
  const saveLoading = isUpdating || isCreating
  const changesPresent = !_.isEqual(buildPayload(data || {}), buildPayload(workingCopy))
  const isMissingReqFields = !workingCopy.name
  const isSaveButtonDisabled = missingBaseUrl || saveLoading
    || isMissingReqFields || !changesPresent || !isAudienceValid(workingCopy)


  return (
    <LoadingContainer isLoading={isLoading} isNotFound={error}>
      <div className='EditRestrictedAudienceConfigurationPage'>
        <header className='AdminHeader'>
          <BackButton className='mb-4' url='/admin/restricted_audiences' />

          <div>
            <h2>{I18N(isNew ? 'create_restricted_audience' : 'update_restricted_audience')}</h2>

            {saveError && <FormErrorList error={saveError} />}
          </div>
        </header>

        {!clearyAdmin && (
          <Banner
            className='BelowAdminHeader mb-4'
            showDecline={false}
            banner={{
              text: I18N('view_and_preview_banner_text'),
            }}
          />
        )}

        <main className='white-card mx-4 p-4'>
          <div className='row mb-4'>
            <div className='form-group col'>
              <label className='required-form-label h5' htmlFor='name-input'>{I18N('audience_name')}</label>
              <input className='form-control form-control-lg' disabled={!clearyAdmin} value={workingCopy.name} onChange={generateChangeHandler('name')} id='name-input' />
            </div>
          </div>

          {clearyAdmin && (
            <>
              <h5>{I18N('audience_toggle_selector.select_audience')}</h5>

              <AudienceToggleSelector
                workingCopy={workingCopy}
                updateWorkingCopy={updateWorkingCopy}
                translationsPath='views.admin.restricted_audience_configuration_editor.audience_toggle_selector'
                canTargetSpecificUsers
                showLabel={false}
                moduleName={null}
              />
            </>
          )}

          <div className='row mt-4'>
            <div className='col col-7'>
              <h6>{I18N('company_settings_override')}</h6>

              <div className='CompanySettingsGrid mb-3'>
                <SettingsCheckboxes
                  settings={ENABLED_CONFIGURATIONS_SETTINGS}
                  appendEnabledToSettingsKey
                  workingCopy={workingCopy}
                  generateChangeHandler={generateChangeHandler}
                />
              </div>

              <h6>{I18N('search_settings')}</h6>

              <div className='CompanySettingsGrid mb-3'>
                <SettingsCheckboxes
                  settings={SEARCH_SETTINGS}
                  appendEnabledToSettingsKey
                  workingCopy={workingCopy}
                  generateChangeHandler={generateChangeHandler}
                />
              </div>

              <h6>{I18N('journeys_settings')}</h6>

              <div className='CompanySettingsGrid mb-3'>
                <SettingsCheckboxes
                  settings={JOURNEYS_SETTINGS}
                  appendEnabledToSettingsKey
                  workingCopy={workingCopy}
                  generateChangeHandler={generateChangeHandler}
                />
              </div>

              <h6>{I18N('feed_settings')}</h6>

              <div className='CompanySettingsGrid mb-3'>
                <SettingsCheckboxes
                  settings={FEED_SETTINGS}
                  workingCopy={workingCopy}
                  generateChangeHandler={generateChangeHandler}
                />
              </div>
            </div>

            <div className='col col-5'>
              <h6>{I18N('user_import_override')}</h6>

              <div className='row'>
                <div className='form-group col'>
                  <div className='form-check'>
                    <input id='enable-notifications-checkbox' type='checkbox' disabled={!clearyAdmin} checked={workingCopy.enableNotifications} onChange={generateChangeHandler('enableNotifications')} />
                    <label className='checkbox-label form-inline-check' htmlFor='enable-notifications-checkbox'>{I18N('enable_notifications')}</label>
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='form-group col'>
                  <div className='form-check'>
                    <input id='hide-users-checkbox' type='checkbox' disabled={!clearyAdmin} checked={workingCopy.hideUsers} onChange={generateChangeHandler('hideUsers')} />
                    <label className='checkbox-label form-inline-check' htmlFor='hide-users-checkbox'>{I18N('hide_users')}</label>
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='form-group col'>
                  <div className='form-check'>
                    <input id='visible-in-org-chart-checkbox' type='checkbox' disabled={!clearyAdmin} checked={workingCopy.visibleInOrgChart} onChange={generateChangeHandler('visibleInOrgChart')} />
                    <label className='checkbox-label form-inline-check' htmlFor='visible-in-org-chart-checkbox'>{I18N('visible_in_org_chart')}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='row mt-4'>
            <div className='form-group col'>
              <label className={classNames({ 'required-form-label': baseUrlRequired }, 'h5')} htmlFor='home-base-url-input'>{I18N('home_base_path')}</label>
              <input
                className='form-control form-control-lg'
                disabled={!clearyAdmin}
                value={workingCopy?.enabledConfigurations?.home?.baseUrl || ''}
                onChange={generateChangeHandler('enabledConfigurations.home.baseUrl')}
                id='home-base-url-input'
                placeholder='/pages'
              />
            </div>
          </div>


          <div id='banner-buttons' className='row mt-4'>
            <div className='col'>
              {clearyAdmin && (
                <>
                  <Button disabled={isSaveButtonDisabled} className='mr-3' showLoadingSpinner={saveLoading} onClick={onSave}>
                    {I18NCommon(isNew ? 'create' : 'save')}
                  </Button>

                  {!isNew && (
                    <Button variant='danger' className='mr-3' onClick={onDelete} showLoadingSpinner={isDestroying} disabled={isDestroying}>
                      {I18NCommon('delete')}
                    </Button>
                  )}
                </>
              )}

              <Button variant='secondary' onClick={onPreview}>
                {I18NCommon('preview')}
              </Button>
            </div>
          </div>
        </main>


        {data?.versions && (
          <div className='mx-4 mt-4'><VersionsTable versions={data.versions} /></div>
        )}
      </div>
    </LoadingContainer>
  )
}

export default EditRestrictedAudienceConfigurationPage
