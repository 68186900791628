import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams, Link } from 'react-router-dom'

import userSlice from 'redux/slices/users'
import { Button } from 'components/common/buttons'
import { i18nPath } from 'utils/i18nHelpers'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import UserPermissionsTab from 'components/admin/users/userEditor/userPermissionsTab'
import AdminAlertsTab from 'components/admin/users/userEditor/adminAlertsTab'

import BasicInformationSection from 'components/admin/users/userEditor/basicInformationSection'
import RoleDetailsSection from 'components/admin/users/userEditor/roleDetailsSection'
import LocationAndTeamsSection from 'components/admin/users/userEditor/locationAndTeamsSection'
import PersonalInformationSection from 'components/admin/users/userEditor/personalInformationSection'
import ContactInformationSection from 'components/admin/users/userEditor/contactInformationSection'
import PhotosSection from 'components/admin/users/userEditor/photosSection'
import UserSettingsSection from 'components/admin/users/userEditor/userSettingsSection'
import SoftLaunchAccessSection from 'components/admin/users/userEditor/softLaunchAccessSection'
import LegalAcknowledgementsSection from 'components/admin/users/userEditor/legalAcknowledgementsSection'
import ExtraUserFieldsSection from 'components/admin/users/userEditor/extraUserFieldsSection'

import UserFormErrorBanner from 'components/admin/users/userEditor/userFormErrorBanner'
import DeleteUserButtonAndModal from 'components/admin/users/userEditor/deleteUserButtonAndModal'
import Error404 from 'components/errors/404'
import VersionsTable from 'components/common/versionsTable'
import UserEditorTabs from 'components/admin/users/userEditor/userEditorTabs'
import useScrollToOnError from 'components/common/hooks/useScrollToOnError'
import useQueryParams from 'components/common/hooks/useQueryParams'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import AuthTokensTab from 'components/admin/users/userEditor/authTokensTab'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'
import BulkUpdateManagersModal from 'components/admin/users/userEditor/bulkUpdateManagersModal'
import useIsCleary from 'components/common/hooks/useIsCleary'

const I18N = i18nPath('views.admin.users')

const GENERAL_INFORMATION_TAB = 'general_information'
const USER_PERMISSIONS_TAB = 'user_permissions'
const ADMIN_ALERTS_TAB = 'admin_alerts'
const AUTH_TOKENS_TAB = 'auth_tokens'

const OMMITED_FIELDS = [
  'descendantsCount',
  'exempt',
  'locale',
  'managerEmployeeId',
  'onLeave',
  'onboardingPhase',
  'photos',
  'primaryPhotoThumbnailUrl',
  'primaryPhotoUrl',
  'defaultPhotoUrl',
  'versions',
  'virtualFields',
]

const EditUserPage = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const isCleary = useIsCleary()
  const [workingCopy, setWorkingCopy] = useState({})
  const [isBulkUpdateMangersModalOpen, setIsBulkUpdateMangersModalOpen] = useState(false)
  const { selectedTab } = useQueryParams()

  const params = useParams()

  const { userId } = params

  const user = useSelector(userSlice.selectors.getUser(userId))

  const permissions = user.virtualFields?.permissions
  const currentAllowedPermissionsLength = permissions?.filter(permission => permission.value).length

  const currentUser = useCurrentUser()
  const currentCompany = useCurrentCompany()
  const {
    isSaving, isLoading, error, isNotFound,
  } = useSelector(userSlice.selectors.getAdminUserMetaData())

  const versions = user.versions || []
  const emailVersions = user.emailVersions || []
  const allVersions = versions.concat(emailVersions)
  const teamIds = (user.groups || []).map(team => team.id).sort()
  const assistedUsersIds = (user.assistedUsers || []).map(user => user.id).sort()
  const photos = user.photos || []
  const primaryImage = _.find(photos, { primary: true })

  const primaryImageId = primaryImage?.id
  const primaryPhotoUrl = primaryImage?.imageUrl || user.defaultPhotoUrl
  const extraUserFields = user.extraUserFields || []

  const changesPresent = !_.isEqual(_.omit(user, OMMITED_FIELDS), _.omit(workingCopy, OMMITED_FIELDS))
  const isMissingEmail = workingCopy.preboarding ? !workingCopy.personalEmail : !workingCopy.primaryEmail
  // eslint-disable-next-line max-len
  const isMissingRequiredFields = !workingCopy.firstName || !workingCopy.lastName || !workingCopy.username || isMissingEmail
  const disableSaveButton = isMissingRequiredFields || !changesPresent
  const renderHrisSyncAlert = currentCompany.hasHrisIntegration
  const isSuperAdmin = currentUser.permissions.superAdmin
  const isUserManager = currentUser.permissions.userManager
  const isClearyAdmin = currentUser.permissions.clearyAdmin
  const settings = currentCompany.settings
  const headerRef = useScrollToOnError(error)

  const enabledCompanyUserExtraFields = (settings?.extraUserFields?.fields || []).filter(field => field.editable)

  const [generateMagicLink, { isLoading: isSendingMagicLink }] = useApi(
    API.auth.admin.generateMagicLink,
    { toastSuccessMessage: I18N('magic_link_sent', { userName: workingCopy.preferredFullName }) }
  )

  useEffect(() => {
    dispatch(userSlice.asyncActions.admin.fetchUser(userId))
    dispatch(userSlice.asyncActions.admin.fetchSelectableOptionValues())

    if (isSuperAdmin) {
      dispatch(userSlice.asyncActions.admin.fetchUserPermissions(userId))
    }

    return () => {
      dispatch(userSlice.actions.clear())
    }
  }, [])

  useEffect(() => {
    const lastVersion = _.last(versions) || {}
    const changes = Object.keys(lastVersion?.changeset || {})

    const newWorkingCopy = {
      ...workingCopy,
      ..._.pick(user, changes),
      versions,
      photos,
    }

    setWorkingCopy(newWorkingCopy)
  }, [versions.length])

  const photosDependency = JSON.stringify(photos.map(({ id, imageUrl }) => [id, imageUrl]))

  useEffect(() => {
    const newWorkingCopy = {
      ...workingCopy,
      photos,
      primaryPhotoUrl: user.primaryPhotoUrl,
      primaryPhotoThumbnailUrl: user.primaryPhotoThumbnailUrl,
    }

    setWorkingCopy(newWorkingCopy)
  }, [primaryImageId, photosDependency])

  useEffect(() => {
    if (user?.id && !isLoading) {
      setWorkingCopy(user)
    }
    // eslint-disable-next-line max-len
  }, [isLoading, user?.id, JSON.stringify(teamIds), user?.managerEmployeeId, JSON.stringify(assistedUsersIds), currentAllowedPermissionsLength, JSON.stringify(extraUserFields)])

  const saveUser = () => {
    const userObject = _.omit(workingCopy, OMMITED_FIELDS)

    if (userObject.extraUserFields?.length > 0) {
      userObject.extraUserFieldsAttributes = workingCopy.extraUserFields.map((extraUserField) => {
        if (_.isNil(extraUserField.id) && _.isEmpty(extraUserField.value)) {
          return null
        }

        const newExtraUserField = {
          id: extraUserField.id,
          value: extraUserField.value,
          name: extraUserField.name,
        }

        if (newExtraUserField.id && _.isEmpty(newExtraUserField.value)) {
          newExtraUserField._destroy = true // eslint-disable-line no-underscore-dangle
        }

        return newExtraUserField
      }).filter(Boolean)
    }

    const params = { hideErrorToastMessage: true }

    const onSuccess = () => {
      const wasDeactivated = user?.active && !userObject.active
      const wasEndDateSet = !user?.endDate && userObject.endDate
      const hasDirectReports = user?.directReportIds?.length > 0

      if (hasDirectReports && (wasDeactivated || wasEndDateSet)) {
        setIsBulkUpdateMangersModalOpen(true)
      }
    }

    dispatch(userSlice.asyncActions.admin.updateUser(userObject, params, onSuccess))
  }

  const onViewProfileClick = () => {
    history.push(`/profile/${user?.username}`)
  }

  const onSendMagicLinkClick = () => {
    const params = {
      preboarding: workingCopy.preboarding,
      email: workingCopy.preboarding ? workingCopy.personalEmail : workingCopy.primaryEmail,
    }

    generateMagicLink(params)
  }

  if (isLoading) {
    return <CirclesLoadingIndicator />
  }

  if (isNotFound) {
    return <Error404 />
  }

  return (
    <div className='EditUserPage'>
      <header className='AdminHeader d-flex justify-content-between align-items-center' ref={headerRef}>
        <div className='d-flex justify-content-center align-items-center'>
          {primaryPhotoUrl && (
            <img className='bordered-thumbnail mr-3' alt='Profile primary photo' src={primaryPhotoUrl} />
          )}
          <h3>{user?.preferredFullName}</h3>
        </div>
        <div className='d-flex flex-row'>
          {isClearyAdmin && !isCleary && (
            <Link to={`/api/admin/impersonate_user?user_id=${user.id}`} target='_blank' className='mr-2 btn btn-secondary'>
              {I18N('impersonate_user')}
            </Link>
          )}
          {isSuperAdmin && (
            <Button
              variant='secondary'
              onClick={onSendMagicLinkClick}
              className='mr-2'
              disabled={isSendingMagicLink}
              showLoadingSpinner={isSendingMagicLink}
            >
              {I18N('send_magic_link')}
            </Button>
          )}
          <Button variant='secondary' onClick={onViewProfileClick}>
            {I18N('view_profile')}
          </Button>
        </div>
      </header>
      {error && <UserFormErrorBanner error={error} title={I18N('errors_found')} />}
      <main className='AdminContent'>
        <div className='d-flex justify-content-between mb-3'>
          <UserEditorTabs />
          {selectedTab === GENERAL_INFORMATION_TAB && (
            <Button disabled={disableSaveButton} onClick={saveUser} showLoadingSpinner={isSaving}>
              {I18N('update_user')}
            </Button>
          )}

        </div>
        {renderHrisSyncAlert && (
          <div className='alert alert-warning'>
            <div>{I18N('banner_hris_sync_alert', { hris: currentCompany.hrisIntegrationName })}</div>
          </div>
        )}
        {selectedTab === GENERAL_INFORMATION_TAB && (
          <>
            <BasicInformationSection user={workingCopy} setUser={setWorkingCopy} />
            <RoleDetailsSection user={workingCopy} setUser={setWorkingCopy} />
            <LocationAndTeamsSection user={workingCopy} setUser={setWorkingCopy} />
            <PersonalInformationSection user={workingCopy} setUser={setWorkingCopy} />
            <ContactInformationSection user={workingCopy} setUser={setWorkingCopy} />
            <PhotosSection user={workingCopy} setUser={setWorkingCopy} />
            {enabledCompanyUserExtraFields.length > 0
              && (
                <ExtraUserFieldsSection
                  user={workingCopy}
                  setUser={setWorkingCopy}
                />
              )}
            {settings.softLaunchWhitelist.enabled && <SoftLaunchAccessSection user={workingCopy} />}
            {settings.legalNotices.nuxModal.enabled && <LegalAcknowledgementsSection user={workingCopy} />}
            <UserSettingsSection user={workingCopy} setUser={setWorkingCopy} />
            <div className='mt-4 mb-5 d-flex flex-row justify-content-between align-items-center'>
              {isUserManager && <DeleteUserButtonAndModal user={user} />}
              <Button disabled={disableSaveButton} onClick={saveUser} showLoadingSpinner={isSaving}>
                {I18N('update_user')}
              </Button>
            </div>
            {allVersions && <VersionsTable versions={allVersions} ignoredFields={['id', 'recordId', 'recordType']} />}
          </>
        )}
        {selectedTab === USER_PERMISSIONS_TAB && <UserPermissionsTab user={user} />}
        {selectedTab === ADMIN_ALERTS_TAB && <AdminAlertsTab user={user} />}
        {selectedTab === AUTH_TOKENS_TAB && <AuthTokensTab user={user} />}
        {isBulkUpdateMangersModalOpen && (
          <BulkUpdateManagersModal
            isOpen={isBulkUpdateMangersModalOpen}
            toggle={() => setIsBulkUpdateMangersModalOpen(!isBulkUpdateMangersModalOpen)}
            user={user}
          />
        )}
      </main>
    </div>
  )
}

export default EditUserPage
