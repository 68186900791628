import useFetch from 'components/common/hooks/useFetch'
import { TableLoadingContainer } from 'components/common/loadingContainer'
import SmartTable from 'components/common/tables/smartTable'
import React, { useState } from 'react'
import API from 'services/api'
import { i18nMoment, i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.admin.impersonation_history')

const defaultPaginationParams = { sortBy: 'created_at', sortDir: 'desc', page: 1 }

const ImpersonationHistoryPage = () => {
  const [paginationParams, setPaginationParams] = useState(defaultPaginationParams)
  const [impersonations, setImpersonations] = useState<any[]>([])

  const {
    isLoading, paginationData: { page, totalPages }, isNotFound,
  } = useFetch(() => API.admin.company.impersonationHistory.fetchAll({
    ...paginationParams,
  }), [paginationParams], {
    onSuccess: setImpersonations,
  })

  const columns = [
    {
      header: I18N('impersonated_user'),
      col: 'col-impersonated-user',
      accessor: d => d.user.preferredFullName,
    },
    {
      header: I18N('impersonator'),
      col: 'col-impersonator',
      accessor: d => d.clearyAdmin.preferredFullName,
    },
    {
      header: I18N('logged_in_at'),
      col: 'col-logged-in-at',
      accessor: d => (d.usedAt ? i18nMoment(d.usedAt).format('LLL') : ''),
    },
  ]

  return (
    <div className='CompanyListPage'>
      <header className='AdminHeader d-flex justify-content-between'>
        <h3 className='mb-0'>{I18N('impersonation_history')}</h3>
      </header>

      <main className='AdminContent'>
        <div className='CompaniesTable row mb-4'>
          <div className='col'>
            <TableLoadingContainer isLoading={isLoading} isNotFound={isNotFound}>
              <div className='overflow-x-auto'>
                <SmartTable
                  data={impersonations}
                  sortKey='created_at'
                  sortedAsc={false}
                  showPagination={page !== null && totalPages !== null}
                  columns={columns}
                  page={page}
                  pages={totalPages}
                  onPaginationClick={setPaginationParams}
                  onSortChange={setPaginationParams}
                  className='white-bg-table'
                />
              </div>
            </TableLoadingContainer>
          </div>
        </div>
      </main>
    </div>
  )
}

export default ImpersonationHistoryPage
