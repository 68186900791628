import React, { useState } from 'react'
import { I18NCommon } from 'utils/i18nHelpers'
import ReactShowMoreText from 'react-show-more-text'
import RichTextView from 'components/common/richTextView'
import FAQDropdown from 'components/pages/faqs/faqDropdown'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'
import FAQForm from 'components/pages/faqs/faqForm'
import { PageFAQType } from 'types/page/faq'
import FAQLastUpdater from 'components/pages/faqs/faqLastUpdater'
import { useHistory } from 'react-router-dom'
import isFirstClickableElement from 'utils/isFirstClickableElement'
import classNames from 'classnames'
import FAQSources from 'components/pages/faqs/faqSources'
import FAQActivity from 'components/pages/faqs/faqActivity'

type Props = {
  pageId?: string
  faq: PageFAQType
  onDelete?: () => void
  onUpdate?: (updatedFAQ: PageFAQType) => void
  canManageFAQs?: boolean
  collapsibleContent?: boolean
  linkToShowPage?: boolean
  showActivity?: boolean
  hasBorder?: boolean
}

const FAQItem: React.FC<Props> = ({
  pageId = '',
  faq,
  onDelete = () => {},
  onUpdate = () => {},
  canManageFAQs = false,
  collapsibleContent = false,
  linkToShowPage = false,
  showActivity = false,
  hasBorder = true,
}) => {
  const history = useHistory()
  const [isEditing, setIsEditing] = useState(false)
  const { question } = faq

  const [updateFAQ, { isLoading: isSaving }] = useApi(updatedFAQ => API.pagesFAQs.update(pageId, updatedFAQ), {
    onSuccess: (data) => {
      onUpdate(data)
      setIsEditing(false)
    },
  })

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (isFirstClickableElement(e, `FAQItem-${faq.id}`)) {
      history.push(faq.displayPath)
    }
  }

  return (
    <div
      id={`FAQItem-${faq.id}`}
      className={classNames('FAQItem position-relative', hasBorder ? 'bordered' : 'p-1', linkToShowPage && 'pointer hover-highlight-border')}
      onClick={linkToShowPage ? handleClick : undefined}
    >
      <FAQDropdown
        pageId={pageId}
        faq={faq}
        onDelete={onDelete}
        setIsEditing={setIsEditing}
        canManageFAQs={canManageFAQs}
      />
      <FAQLastUpdater faq={faq} className='mb-3' />

      {isEditing ? (
        <FAQForm
          onSave={updateFAQ}
          faq={faq}
          onCancel={() => setIsEditing(false)}
          isSaving={isSaving}
        />
      ) : (
        <>
          <h4>{question}</h4>
          {collapsibleContent ? (
            <ReactShowMoreText
              lines={3}
              more={<span className='link-color pointer'>{I18NCommon('more')}</span>}
              less={<span className='link-color pointer'>{I18NCommon('show_less')}</span>}
              className='w-100'
              anchorClass='ShowMoreText__anchor'
            >
              <RichTextView record={faq} richTextKey='answer' recordType='pagesFAQ' />
            </ReactShowMoreText>
          ) : (
            <>
              <RichTextView record={faq} richTextKey='answer' recordType='pagesFAQ' />
              <FAQSources faq={faq} />
            </>
          )}
          {showActivity && (
            <>
              <hr />
              <FAQActivity faqId={faq.id} />
            </>
          )}
        </>
      )}
    </div>
  )
}

export default FAQItem

