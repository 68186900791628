import React, { useEffect, useMemo } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { i18nPath } from 'utils/i18nHelpers'

import TasksAdminTable, { MY_TASKS, ALL_OPEN_TASKS, ALL_COMPLETED_TASKS } from 'components/admin/journeys/tasks/tasksAdminTable'
import HistoryChangingPillTabSelector from 'components/common/historyChangingPillTabSelector'
import useGlobalStateBucket from 'components/common/hooks/useGlobalStateBucket'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import API from 'services/api'
import TaskFilters from 'components/admin/journeys/tasks/taskFilters'
import useTaskFilters from 'components/admin/journeys/hooks/useTaskFilters'
import { useDispatch, useSelector } from 'react-redux'
import useIsLoaded from 'components/common/hooks/useIsLoaded'
import userSlice from 'redux/slices/users'
import normalizeTargetingRules from 'utils/normalizeTargetingRules'
import taskableStepSlice from 'redux/slices/journeys/taskableSteps'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import ExportXlsxLink from 'components/common/exportXlsxLink'

const I18N = i18nPath('views.admin.journeys.tasks.task_list_page')

const FIXED_TABS = [MY_TASKS, ALL_OPEN_TASKS, ALL_COMPLETED_TASKS]
const SPECIFIC_GROUP_ROLE = 'specific_group'

const normalizeTargetingRulesIfPresent = (targetingRules) => {
  if (!targetingRules) return null

  return normalizeTargetingRules(targetingRules)
}

const TaskListPage = () => {
  const dispatch = useDispatch()
  const currentUser = useCurrentUser()
  const { selectedTab } = useParams()
  const { settings } = useCurrentCompany()

  const isCompletedTaskTabSelected = selectedTab === ALL_COMPLETED_TASKS
  const isMyTasksTabSelected = selectedTab === MY_TASKS

  const { data: groupCorrespondents = [] } = useGlobalStateBucket(
    () => API.admin.journey.correspondents.fetchAll({ role: SPECIFIC_GROUP_ROLE, includeGroupMembers: true }),
    'groupCorrespondents',
    settings.groups.enabled
  )

  const { filteredGroupCorrespondents, groupTabNames } = useMemo(() => {
    const filtered = groupCorrespondents.filter(c => c.group?.isCurrentUserMember)
    const names = filtered.map(c => `group-${c.group?.id}`)
    return { filteredGroupCorrespondents: filtered, groupTabNames: names }
  }, [groupCorrespondents])

  const selectedToCorrespondent = groupCorrespondents.find(c => `group-${c.group?.id}` === selectedTab)

  const {
    toUserFilter,
    setToUserFilter,
    selectedToUserFilter,
    forUserFilter,
    setForUserFilter,
    selectedForUserFilter,
    searchQuery,
    setSearchQuery,
    toUserTargetingRules,
    setToUserTargetingRules,
    completedToUserTargetingRules,
    forUserTargetingRules,
    setForUserTargetingRules,
    completedForUserTargetingRules,
  } = useTaskFilters()

  const { isLoading: isLoadingUsers } = useSelector(userSlice.selectors.getMetaData())
  const areUsersFromUrlLoaded = useIsLoaded(isLoadingUsers) || selectedToUserFilter?.id || selectedForUserFilter?.id

  const { queryParams } = useSelector(taskableStepSlice.selectors.getMetaData())

  const buildParams = (newQueryParams) => {
    // Open tasks are tasks with status of pending
    const selectedStatusFilter = isCompletedTaskTabSelected ? 'completed' : 'pending'
    const selectedToUserIdFilter = isMyTasksTabSelected ? currentUser.id : selectedToUserFilter?.id

    return {
      ...queryParams,
      status: selectedStatusFilter,
      q: searchQuery,
      userId: selectedForUserFilter?.id,
      toUserId: selectedToUserIdFilter,
      toCorrespondentId: selectedToCorrespondent?.id,
      toUserTargetingRules: normalizeTargetingRulesIfPresent(completedToUserTargetingRules),
      forUserTargetingRules: normalizeTargetingRulesIfPresent(completedForUserTargetingRules),
      ...newQueryParams,
      sortBy: 'due_at',
      sortDir: 'asc',
    }
  }

  const onParamsChange = (newQueryParams) => {
    dispatch(taskableStepSlice.asyncActions.admin.fetchAll(buildParams(newQueryParams)))
  }

  useEffect(() => {
    if (!selectedTab) return
    if ((toUserFilter || forUserFilter) && !areUsersFromUrlLoaded) return

    onParamsChange({ page: 1 })
  }, [
    selectedTab,
    selectedToUserFilter?.id,
    selectedForUserFilter?.id,
    searchQuery,
    areUsersFromUrlLoaded,
    completedToUserTargetingRules,
    completedForUserTargetingRules,
  ])

  const possibleTabs = useMemo(() => [...FIXED_TABS, ...groupTabNames], [groupTabNames])

  if (!selectedTab || !possibleTabs.includes(selectedTab)) {
    return <Redirect exact to='/admin/tasks/my_tasks' />
  }

  const groupTabs = filteredGroupCorrespondents.map(c => ({ id: `group-${c.group?.id}`, text: I18N('group_tab_name', { groupName: c.group?.name }) }))

  return (
    <div className='TaskListPage'>
      <header className='AdminHeader d-flex justify-content-between align-items-center'>
        <h3 className='mb-1'>{I18N('tasks')}</h3>
        <ExportXlsxLink
          endpoint={API.admin.taskableSteps.exportXlsx}
          exportParams={buildParams({ page: 1, perPage: queryParams?.total })}
        />
      </header>
      <main className='AdminContent'>
        <HistoryChangingPillTabSelector
          baseUrl='/admin/tasks'
          historyKeyToChange='pathname'
          tabs={[
            { id: MY_TASKS, text: I18N(`${MY_TASKS}_tab_name`) },
            ...groupTabs,
            { id: ALL_OPEN_TASKS, text: I18N(`${ALL_OPEN_TASKS}_tab_name`) },
            { id: ALL_COMPLETED_TASKS, text: I18N(`${ALL_COMPLETED_TASKS}_tab_name`) },
          ]}
          pillClasses='text-normal'
        />
        <TaskFilters
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          selectedToUserFilter={selectedToUserFilter}
          setToUserFilter={setToUserFilter}
          selectedForUserFilter={selectedForUserFilter}
          setForUserFilter={setForUserFilter}
          toUserTargetingRules={toUserTargetingRules}
          setToUserTargetingRules={setToUserTargetingRules}
          completedToUserTargetingRules={completedToUserTargetingRules}
          forUserTargetingRules={forUserTargetingRules}
          setForUserTargetingRules={setForUserTargetingRules}
          completedForUserTargetingRules={completedForUserTargetingRules}
          isMyTasksTabSelected={isMyTasksTabSelected}
        />
        <TasksAdminTable
          onParamsChange={onParamsChange}
          isLoadingUsers={isLoadingUsers}
          selectedToCorrespondent={selectedToCorrespondent}
        />
      </main>
    </div>
  )
}

export default TaskListPage
