import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import taskableStepSlice from 'redux/slices/journeys/taskableSteps'
import { i18nPath, i18nMoment } from 'utils/i18nHelpers'
import { TableLoadingContainer } from 'components/common/loadingContainer'
import SmartTable from 'components/common/tables/smartTable'
import StepLink from 'components/admin/journeys/stepLink'
import EmployeeOrCorrespondentLink from 'components/journeys/employeeOrCorrespondentLink'
import DueAtTextComponent from 'components/journeys/tasks/dueAtTextComponent'
import TaskableDetailsButtonAndModal from 'components/journeys/modals/taskableDetailsButtonAndModal'
import classNames from 'classnames'
import useCompleteTaskables from 'components/admin/journeys/hooks/useCompleteTaskables'
import AssignTaskSelect from 'components/admin/journeys/tasks/assignTaskSelect'

export const MY_TASKS = 'my_tasks'
export const ALL_OPEN_TASKS = 'open'
export const ALL_COMPLETED_TASKS = 'completed'

const I18N = i18nPath('views.admin.journeys.tasks.task_list_page')

const TasksAdminTable = ({
  onParamsChange,
  isLoadingUsers,
  selectedToCorrespondent,
}) => {
  const { selectedTab } = useParams()
  const isCompletedTaskTabSelected = selectedTab === ALL_COMPLETED_TASKS

  const isGroupTab = !!selectedToCorrespondent

  const { queryParams, isLoading } = useSelector(taskableStepSlice.selectors.getMetaData())

  const {
    page, totalPages, perPage, total,
  } = queryParams

  const {
    filteredSteps,
    rowClassName,
  } = useCompleteTaskables({
    onParamsChange,
    isCompletedTaskTabSelected,
  })

  const columns = [
    !isCompletedTaskTabSelected && {
      header: I18N('list.completed'),
      accessor: d => (
        <TaskableDetailsButtonAndModal
          showCompleteCheckbox={true}
          showTaskableName={false}
          className='justify-content-center'
          step={d}
        />
      ),
    },
    {
      header: I18N('list.name'),
      accessor: d => <StepLink step={d} />,
    },
    {
      header: I18N('list.assigned_to'),
      accessor: (d) => {
        if (isGroupTab) {
          return (
            <AssignTaskSelect
              key={d.id}
              step={d}
              toCorrespondent={selectedToCorrespondent}
            />
          )
        } else {
          return (
            <EmployeeOrCorrespondentLink
              linkToAdmin
              user={d?.toUserOrToCorrespondentUser}
              correspondent={d?.toCorrespondent}
            />
          )
        }
      },
    },
    {
      header: I18N('list.for'),
      accessor: d => <EmployeeOrCorrespondentLink linkToAdmin user={d?.forUser} />,
    },
    !isCompletedTaskTabSelected && {
      header: I18N('list.due_date'),
      accessor: d => <DueAtTextComponent className='mb-0' task={d?.entity} hasDuePrefix={false} />,
    },
    isCompletedTaskTabSelected && {
      header: I18N('list.completion_date'),
      accessor: d => <p className='mb-0'>{i18nMoment(d?.entity?.completedAt).format('lll')}</p>,
    },
  ].filter(Boolean)

  return (
    <>
      <TableLoadingContainer isLoading={isLoading || isLoadingUsers}>
        <SmartTable
          className={classNames('mt-3 white-bg-table TasksAdminTable', { isCompletedTaskTabSelected })}
          data={filteredSteps}
          page={page}
          pages={totalPages}
          perPage={perPage}
          totalCount={total}
          showPagination={true}
          columns={columns}
          onPaginationClick={onParamsChange}
          rowClassName={rowClassName}
        />
      </TableLoadingContainer>
    </>
  )
}

export default TasksAdminTable
