import useQueryParams from 'components/common/hooks/useQueryParams'
import React, { useEffect } from 'react'
import useDrivePicker from 'react-google-drive-picker'
import pageVariables from 'utils/pageVariables'

const GoogleDrivePickerPage = () => {
  const { redirectUri } = useQueryParams()

  const [openPicker] = useDrivePicker()

  const handleOpenPicker = () => {
    openPicker({
      clientId: pageVariables.googleDriveApiClientId,
      developerKey: pageVariables.googleDriveApiDeveloperKey,
      viewId: 'DOCS',
      showUploadView: false,
      showUploadFolders: false,
      supportDrives: false,
      multiselect: true,
      setSelectFolderEnabled: true,
      setIncludeFolders: true,
      callbackFunction: (data) => {
        if (data.action === 'picked') {
          const ids = data.docs.map(doc => doc.id)
          window.location.href = `${redirectUri}?google_drive_file_ids[]=${ids.join('&google_drive_file_ids[]=')}`
        } else if (data.action === 'cancel') {
          window.location.href = redirectUri
        }
      },
    })
  }

  useEffect(() => {
    const originalOpen = window.open
    window.open = function (URL, name, specs, replace) {
      const newWindow = originalOpen(URL, name, specs, replace)
      if (URL.indexOf('https://accounts.google.com/') === 0) {
        const interval = setInterval(() => {
          if (newWindow.closed) {
            clearInterval(interval)
            setTimeout(() => {
              if (!document.querySelector('.picker')) {
                window.location.href = redirectUri
              }
            }, 1000)
          }
        }, 1000)
      }
      return newWindow
    }
  }, [])


  // useEffect(() => {
  //   const openPickerIfGoogleIsDefined = () => {
  //     if (window.google) {
  //       handleOpenPicker()
  //     } else {
  //       setTimeout(openPickerIfGoogleIsDefined, 100)
  //     }
  //   }

  //   openPickerIfGoogleIsDefined()
  // }, [])

  return (
    <div>
      <button onClick={handleOpenPicker}>Open Picker</button>
    </div>
  )
}

export default GoogleDrivePickerPage
