import React from 'react'
import Activities from 'pages/ai_platform/dashboardPage/activities'

const MainContent = () => (
  <div>
    <Activities />
  </div>
)

export default MainContent
