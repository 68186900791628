import React from 'react'
import Sidebar from 'pages/ai_platform/dashboardPage/sidebar'
import MainContent from 'pages/ai_platform/dashboardPage/mainContent'

const AiPlatformDashboardPage = () => (
  <div className='AiPlatformDashboardPage d-flex justify-content-center mt-5'>
    <div className='AiPlatformDashboardPage__wrapper d-flex gap-4'>
      <div className='AiPlatformDashboardPage__sidebar-wrapper'>
        <Sidebar />
      </div>
      <div className='AiPlatformDashboardPage__main-content-wrapper'>
        <MainContent />
      </div>
    </div>
  </div>
)

export default AiPlatformDashboardPage
