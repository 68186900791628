import { AnswerStatusLabel } from 'components/analytics/ai_answers/aiAnswers'
import AiAnswersOrigin from 'components/analytics/ai_answers/aiAnswersOrigin'
import EmployeeLinkWithPopover from 'components/common/employeeLinkWithPopover'
import React, { useEffect, useRef, useState } from 'react'
import { i18nMoment, i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.platform_analytics.ai_answers')

const AiAnswerSidebarTitle = ({ answer }) => {
  const titleRef = useRef<HTMLDivElement>(null)
  const [titleClassName, setTitleClassName] = useState('text-large font-weight-700')

  useEffect(() => {
    if (titleRef.current?.scrollHeight && titleRef.current.scrollHeight > 60) {
      setTitleClassName('font-weight-700')
    }
  }, [])

  return (
    <>
      <AiAnswersOrigin answer={answer} showChannel />
      <div className='my-3' />
      <AnswerStatusLabel answer={answer} />
      <div className='d-flex align-items-center gap-1 mt-4'>
        <span className='text-secondary text-small'>{I18N('sidebar.submitted_by')}</span>
        <EmployeeLinkWithPopover user={answer?.user} />
        <span>•</span>
        <span className='text-secondary text-small'>
          {i18nMoment(answer?.createdAt).format('lll')}
        </span>
      </div>

      <div className={titleClassName} ref={titleRef}>
        {answer?.queryWithoutMentions}
      </div>

      {answer?.originalMessageUrl && (
        <div>
          <a
            href={answer.originalMessageUrl}
            target='_blank'
            rel='noopener noreferrer'
            className='text-small'
          >
            {I18N('view_original_message')}
          </a>
        </div>
      )}
    </>
  )
}

export default AiAnswerSidebarTitle
