import useApi from 'components/common/hooks/useApi'
import useQueryParams from 'components/common/hooks/useQueryParams'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import API from 'services/api'

type UseGoogleDriveImportProps = {
  sourceId: string
}

const useGoogleDriveImport = ({ sourceId }: UseGoogleDriveImportProps) => {
  const { googleDriveFileIds } = useQueryParams()

  const [importDocuments, { isLoaded: isImporting }] = useApi(API.admin.externalSync.documents.import)
  const history = useHistory()

  useEffect(() => {
    if (googleDriveFileIds && googleDriveFileIds.length > 0) {
      importDocuments(sourceId, googleDriveFileIds)
      // Clear the query param after importing
      history.replace(history.location.pathname)
    }
  }, [JSON.stringify(googleDriveFileIds)])
}

export default useGoogleDriveImport
