import React, { useState, useCallback } from 'react'
import { ExternalSyncDocument, ExternalSyncSource } from 'components/admin/integrations/external_sync/types'
import CdnSvg from 'components/common/cdnSvg'
import ResponsivePopover from 'components/common/responsivePopover'

type CollapsedParentsProps = {
  parents: ExternalSyncDocument[]
  onParentClick: (parent: ExternalSyncDocument | null) => void
}

const CollapsedParents: React.FC<CollapsedParentsProps> = ({ parents, onParentClick }) => {
  const [popoverTarget, setPopoverTarget] = useState<HTMLSpanElement | null>(null)
  const [isOpen, setIsOpen] = useState(false)

  const toggle = useCallback(() => {
    setIsOpen(prev => !prev)
  }, [])

  return (
    <h3 className='d-inline-block ml-2'>
      <span className='mr-2'>&gt;</span>
      <span
        ref={setPopoverTarget}
        onClick={toggle}
        className='pointer'
      >
        ...
      </span>
      {popoverTarget && (
        <ResponsivePopover
          target={popoverTarget}
          isOpen={isOpen}
          toggle={toggle}
        >
          <ul className='ParentsPopoverList list-unstyled'>
            {parents.map(parent => (
              <li
                key={parent.id}
                onClick={() => onParentClick(parent)}
                className='pointer'
              >
                {parent.title}
              </li>
            ))}
          </ul>
        </ResponsivePopover>
      )}
    </h3>
  )
}

type Props = {
  document?: ExternalSyncDocument
  source: ExternalSyncSource
  onParentClick: (parent: ExternalSyncDocument | null) => void
}

const Breadcrumb: React.FC<Props> = ({ document, source, onParentClick }) => {
  const collapsedParents = document?.parents

  return (
    <div className='d-flex flex-row align-items-center'>
      <h3>
        <a onClick={() => onParentClick(null)}>
          <CdnSvg src={source.iconPath} className='d-inline-block mr-2 SourcePageIcon' />
          {source.humanName}
        </a>
      </h3>

      {!_.isEmpty(collapsedParents) && <CollapsedParents parents={collapsedParents!} onParentClick={onParentClick} />}

      {document && (
        <h3 key={document.id} className='d-inline-block ml-2'>
          <a onClick={() => onParentClick(document)}>
            <span className='mr-2'>&gt;</span>
            <span>{document.title}</span>
          </a>
        </h3>
      )}
    </div>
  )
}

export default Breadcrumb
